import type { SVGProps } from 'react'

const ICON_IDS = [
  '18-plus',
  'announcement',
  'arrow-right',
  'back',
  'bank-transfer',
  'bell',
  'bingo',
  'blackjack',
  'calendar',
  'camera',
  'cards',
  'casino',
  'casino-circle-logo',
  'chat',
  'check',
  'check-2',
  'check-circle',
  'checkbox-checked',
  'checkbox-unchecked',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'close',
  'close-2',
  'close-3',
  'competitions',
  'competitions-circle-logo',
  'credit-cards',
  'deposit-tab',
  'dollar-refresh',
  'double-chevron-down',
  'drawer-curtain',
  'electronic-wallets',
  'exclamation-circle',
  'exclamation-mark',
  'eye',
  'eye-2',
  'eye-slash',
  'eye-slash-2',
  'facebook',
  'facebook-f',
  'favorites',
  'free-bonus',
  'free-spin',
  'free-spin-item',
  'gift',
  'google',
  'heart',
  'instagram',
  'jackpots',
  'kyc',
  'live',
  'lobby',
  'login',
  'logout',
  'minus',
  'moon',
  'new',
  'note-bottom-line',
  'note-top-line',
  'others',
  'outline-check-circle',
  'outline-close-circle',
  'outline-close-circle-2',
  'outline-dollar-circle',
  'outline-exclamation-circle',
  'outline-exclamation-circle-2',
  'pencil',
  'play-circle',
  'plus',
  'popular',
  'prepaid-cards',
  'promotions',
  'refresh',
  'refresh-2',
  'refresh-balance',
  'roulettes',
  'scratch',
  'search',
  'send',
  'slots',
  'sports',
  'sports-circle-logo',
  'star',
  'star-2',
  'star-outline',
  'stepper',
  'sun',
  'tab-background',
  'telegram',
  'trash',
  'trash-outline',
  'triangle-left',
  'triangle-left-2',
  'triangle-right',
  'twitch',
  'twitter',
  'twitter-2',
  'wallet',
  'wallet-2',
  'warning',
  'whatsapp',
  'withdraw',
  'withdraw-tab',
  'cash-collect',
  'apple',
  'hamburger',
  'cashier',
  'recently',
  'stepper-gift',
  'register-gift',
  'table',
  'envelope',
  'triangle-down',
  'id-card',
  'camera',
  'circle-check',
  'circle-cross',
  'thunder',
  'video',
  'referral',
  'bell-news',
  'platform-filter',
  'sort',
  'copy',
  'soccer',
  'basketball',
  'american-football',
  'tennis',
  'baseball',
  'ice-hockey',
  'handball',
  'rugby',
] as const

export type IconId = (typeof ICON_IDS)[number]

type IconProps = {
  id: IconId
} & SVGProps<SVGSVGElement>

const Icon = ({ id, ...props }: IconProps) => (
  <svg {...props}>
    <use href={`/sprite.svg?v=${ICON_IDS.length}#${id}`} />
  </svg>
)

export default Icon
