import '../common/platform-filter-modal/platform-filter-modal.css'

import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useNavigate, useParams } from '@tanstack/router'
import type { ChangeEvent, MouseEvent } from 'react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { ApiResponse } from '../../types/api'
import type { Game } from '../../types/game'
import { api } from '../../utils/api-client'
import classNames from '../../utils/classNames'
import closeModal from '../../utils/closeModal'
import { debounce } from '../../utils/common'
import { Spinner, ToastMessage } from '../common'
import Modal from '../common/modal'

const SearchModal = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const modal = useModal()
  const { t } = useTranslation('casino')
  const toastMessage = useModal(ToastMessage)
  const { category } = useParams({ from: '/casino/$category' })
  const navigate = useNavigate()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [filteredList, setFilteredList] = useState<Game[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const search = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value)
    setIsLoading(true)
    try {
      const res = await api.post<Game[]>('/request.php', {
        type: 'search_game',
        key: e.target.value,
      })
      if (Array.isArray(res.data)) {
        setFilteredList(res.data)
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const onPlayGame = async (e: MouseEvent<HTMLDivElement>, game: Game) => {
    ;(e.target as HTMLDivElement).classList.add('[&>.spinner]:flex')

    // Transfer balance to game
    api.post('/ajax_data.php', {
      type: 'auto_transfer_in',
      ...game.gameInfo,
    })

    const reqBody = {
      type: 'play_game_websocket2',
      id: game.gameInfo.id,
      platform: 1,
    }

    const response = await api.post<ApiResponse<string>>(
      '/request.php',
      reqBody,
    )

    const { info, status } = response.data
    if (status === 1) {
      navigate({
        to: '/casino/$category/$game',
        search: {
          gameUrl: encodeURIComponent(info),
          gameId: game.gameInfo.gameId,
          gameCode: game.gameInfo.gameCode,
          isFavorite: '0',
        },
        params: {
          category,
          game: `${game.name.replaceAll(/\W/g, '')}-${game.gameInfo.id}`,
        },
      })
      closeModal(modal)
    } else if (typeof info === 'string') {
      toastMessage.show({
        text: info,
        isValid: false,
      })
    }

    ;(e.target as HTMLDivElement).classList.remove('[&>.spinner]:flex')
  }

  return (
    <Modal
      id="platform-filter-modal"
      containerStyle={classNames(
        'platform-filter-modal animate-slideUp xl:animate-fadeIn top-[unset] xl:top-1/2 left-[unset] xl:left-1/2',
        'bottom-0 xl:bottom-[unset] transform-none xl:-translate-x-1/2 xl:translate-y-[calc(-50%-50px)]',
        'w-full max-w-[500px] border-none rounded-none xl:rounded-xl pl-0 pr-0 !pt-0 !pb-[0px] xl:overflow-hidden',
      )}>
      <div className="p-3 h-[210px]">
        <div className="flex bg-secondary rounded mb-3">
          <div className="flex flex-1 p-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              width="24"
              height="24"
              className="text-highlight mr-2">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <input
              ref={inputRef}
              placeholder={t('search-category')}
              className="flex-1 text-txt"
              onChange={debounce(search, 300)}
            />
          </div>
          <button
            className="p-2"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.value = ''
                setFilteredList([])
                setSearchKeyword('')
              }
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="text-secondary-btn-bg"
              width="24"
              height="24">
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        {isLoading ? (
          <Spinner containerStyle={{ height: '120px' }} />
        ) : filteredList.length === 0 && searchKeyword.length > 0 ? (
          <p className="col-[1/3] text-sm text-[#999] font-normal text-center">
            {t('no-games')}
          </p>
        ) : (
          <div className="flex flex-nowrap overflow-x-auto gap-3">
            {filteredList.slice(0, 30).map((item, index) => (
              <div
                className="flx-str-ctr w-full min-w-[130px] cursor-pointer relative rounded-[10px] [&>*]:pointer-events-none"
                key={index}
                onClick={e => {
                  onPlayGame(e, item)
                }}>
                <div className="spinner absolute w-[130px] h-[130px] hidden left-0 top-0">
                  <Spinner spinnerSize={25} />
                </div>
                <img
                  className="w-[130px] min-w-[130px] h-[130px] object-cover mr-2.5 rounded-[5px]"
                  src={item.imgURL}
                  alt={item.name}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default NiceModal.create(SearchModal)
