import { useEffect } from 'react'

import inIframe from '../utils/inIframe'

const useIframePrevention = () => {
  useEffect(() => {
    if (['/game-deposit', '/game-back'].includes(location.pathname)) return

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'back' }))
    } else if (inIframe()) {
      window.parent.postMessage(JSON.stringify({ type: 'back' }), '*')
    }
  }, [location.pathname])
}

export default useIframePrevention
