import { useTranslation } from 'react-i18next'

import barricade from '../../assets/common/barricade.webp'
import { CustomerServiceButton } from './customer-service'

interface ErrorPageProps {
  type?: '404'
  errorMessage?: string
}

const ErrorPage = ({ type, errorMessage }: ErrorPageProps) => {
  const { t } = useTranslation('common')

  return (
    <div className="v-flx-ctr h-screen bg-primary w-screen fixed z-[200] px-5 py-0 left-0 top-0">
      <h2 className="font-Geometr706-BlkCn-BT text-[50px] text-txt uppercase mb-3 xl:text-6xl">
        OOPS!
      </h2>
      <h3 className="font-Syncopate-Bold text-base text-[#46aef7] uppercase text-center max-w-[600px] mb-10 xl:text-2xl">
        {t('something-went-wrong')}
      </h3>
      <div style={{ maxWidth: 600, position: 'relative' }}>
        {type && (
          <p className="font-Syncopate-Bold absolute -translate-x-2/4 -translate-y-2/4 text-lg text-[#0e091e] left-2/4 top-[33%] xl:text-3xl">
            {type}
          </p>
        )}
        <img className="w-full max-w-[600px]" src={barricade} alt="Barricade" />
        {errorMessage && (
          <p className="text-center text-[#697172] font-medium text-sm uppercase mt-6">
            ERROR: {errorMessage}
          </p>
        )}
      </div>
      {!window.ReactNativeWebView && (
        <div
          className="fixed right-2.5 bottom-[70px] xl:right-5 xl:bottom-5"
          onClick={() => window.fcWidget?.open()}>
          <CustomerServiceButton />
        </div>
      )}
    </div>
  )
}

export default ErrorPage
