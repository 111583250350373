import { isAndroid, isIOS } from 'react-device-detect'
import type { StateCreator } from 'zustand'

import type { Theme } from '../../types/common'
import type { AllSlices } from '../types'
import type { CommonSlice } from '../types/common'

export const createCommonSlice: StateCreator<
  AllSlices,
  [],
  [],
  CommonSlice
> = set => ({
  isChatVisible: false,
  theme: localStorage.getItem('theme')
    ? (localStorage.getItem('theme') as Theme)
    : 'light',
  isTopAdVisible: true,
  isDownloadPwaVisible: false,
  isPendingDepositVisible: false,
  isCoinAnimVisible: false,
  isFullScreen: false,
  isDownloadVisible: isIOS || isAndroid,
  setIsCoinAnimVisible: isVisible => {
    set(state => ({
      common: { ...state.common, isCoinAnimVisible: isVisible },
    }))
    if (isVisible) {
      setTimeout(
        () =>
          set(state => ({
            common: { ...state.common, isCoinAnimVisible: false },
          })),
        3000,
      )
    }
  },
  setIsChatVisible: isVisible =>
    set(state => ({ common: { ...state.common, isChatVisible: isVisible } })),
  setTheme: theme => set(state => ({ common: { ...state.common, theme } })),
  setIsTopAdVisible: isVisible =>
    set(state => ({ common: { ...state.common, isTopAdVisible: isVisible } })),
  setIsDownloadPwaVisible: isVisible =>
    set(state => ({
      common: { ...state.common, isDownloadPwaVisible: isVisible },
    })),
  setIsPendingDepositVisible: isVisible =>
    set(state => ({
      common: { ...state.common, isPendingDepositVisible: isVisible },
    })),
  setIsFullScreen: isFullScreen =>
    set(state => ({
      common: { ...state.common, isFullScreen: isFullScreen },
    })),
  setIsDownloadVisible: isVisible =>
    set(state => ({
      common: { ...state.common, isDownloadVisible: isVisible },
    })),
})
