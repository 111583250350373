import { lazy, Route } from '@tanstack/router'

import { rootRoute } from './root'

const Sponsors = lazy(() => import('../pages/sponsors'))

export const sponsorsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/sponsors',
  component: Sponsors,
})
