import type { HTMLProps, ReactNode } from 'react'
import { useState } from 'react'

import { useBoundStore } from '../../store/store'
import classNames from '../../utils/classNames'
import Icon from '../icon'
import { TailSpin } from '../loaders'

interface TextInputProps extends HTMLProps<HTMLInputElement> {
  suffix?: string
  isPassword?: boolean
  isValid?: boolean
  isReferral?: boolean
  isValidating?: boolean
  error?: string
  label?: string
  isDateInput?: boolean
  Badge?: ReactNode
  variant?: 'secondary'
}

const TextInput = ({
  suffix,
  isPassword,
  isValid,
  isReferral,
  isValidating,
  error,
  label,
  isDateInput,
  value,
  onChange,
  name,
  readOnly,
  Badge,
  variant,
  ...rest
}: TextInputProps) => {
  const theme = useBoundStore(state => state.common.theme)
  const [hide, setHide] = useState(true)

  return (
    <div className="w-full">
      {label && (
        <label className="uppercase text-secondary-txt text-sm block mb-1 xl:text-base">
          {label}
        </label>
      )}
      <div
        className={classNames(
          'flex items-center h-9 relative px-3 py-0 rounded-md xl:h-[38px]',
          isReferral ? 'bg-primary' : variant ? `bg-${variant}` : 'bg-primary',
        )}
        style={{ marginBottom: error ? 9 : 14 }}>
        {suffix && (
          <p className="text-secondary-txt font-normal text-sm mr-2 xl:text-base">
            {suffix}
          </p>
        )}
        <input
          className="placeholder-secondary-txt text-txt text-sm flex-1 mr-3 xl:text-base"
          type={isPassword ? (hide ? 'password' : 'text') : undefined}
          value={value}
          name={name}
          onChange={e => {
            if (!isDateInput) {
              return onChange?.(e)
            }

            const newValue = e.target.value
            const currentValue = (value ?? '') as string

            if (
              newValue.length > 10 ||
              (newValue && !/^\d+$/.test(newValue.replaceAll('/', '')))
            )
              return

            if (currentValue.length < newValue.length) {
              if (newValue.length === 2 || newValue.length === 5) {
                return onChange?.({
                  ...e,
                  target: {
                    ...e.target,
                    name,
                    value: newValue + '/',
                  } as EventTarget,
                })
              }
              if (newValue.length === 3 && newValue.charAt(2) !== '/') {
                return onChange?.({
                  ...e,
                  target: {
                    ...e.target,
                    name,
                    value: newValue.slice(0, 2) + '/' + newValue.slice(-1),
                  } as EventTarget,
                })
              }
              if (newValue.length === 6 && newValue.charAt(5) !== '/') {
                return onChange?.({
                  ...e,
                  target: {
                    ...e.target,
                    name,
                    value: newValue.slice(0, 5) + '/' + newValue.slice(-1),
                  } as EventTarget,
                })
              }
            }

            onChange?.(e)
          }}
          onKeyDown={e => {
            if (!isDateInput) return
            if (e.key === '/') {
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          readOnly={readOnly}
          {...rest}
        />
        {isValidating ? (
          <TailSpin
            color={theme === 'dark' ? '#fff' : '#0E091E'}
            width={18}
            height={18}
          />
        ) : (
          isValid && <Icon id="check-circle" width="18" height="18" />
        )}
        {isPassword && (
          <button
            type="button"
            className="flx-ctr"
            onClick={() => setHide(prev => !prev)}>
            {hide ? (
              <Icon
                id="eye-slash-2"
                width="18"
                height="17"
                fill="#fff"
                className="fill-secondary-txt"
              />
            ) : (
              <Icon
                id="eye-2"
                width="18"
                height="13"
                fill="white"
                className="fill-secondary-txt"
              />
            )}
          </button>
        )}
        {Badge}
      </div>
      {error && (
        <p className="font-normal text-sm leading-4 text-[rgb(255,64,88)] mb-2.5">
          {error}
        </p>
      )}
    </div>
  )
}

export default TextInput
