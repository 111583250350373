import { lazy, Route } from '@tanstack/router'

import type { RegisterSearch } from './register'
import { rootRoute } from './root'

const CHRegister = lazy(() => import('../pages/ch-register'))

export const chRegisterRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/ch-register',
  component: CHRegister,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
