import { useEffect, useState } from 'react'

import { throttle } from '../utils/common'

const useDimensions = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1200)

  useEffect(() => {
    const onResize = throttle(() => {
      setIsDesktop(window.innerWidth >= 1200)
    }, 300)

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return { isDesktop }
}

export default useDimensions
