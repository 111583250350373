import { useEffect } from 'react'

import { getAdsInfo, getFbp } from '../utils/ads-info'
import { api } from '../utils/api-client'
import { decryptUser } from '../utils/user'

function useAdsInfo() {
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    const adsInfo = getAdsInfo()

    const user = decryptUser()
    const search = Object.fromEntries(params.entries())
    const fbp = getFbp()

    if (search.utm_source || search.referral) {
      api.post('/crypto.php', {
        type: 'save_advertise_record',
        meta: {
          ...search,
        },
      })
    }

    if (fbp) {
      search.fbp = fbp
    }

    const latestAdsInfo = { ...adsInfo, ...search }

    if (Object.keys(latestAdsInfo).length && !user) {
      localStorage.setItem(
        'ads_info',
        JSON.stringify(latestAdsInfo),
      )
    }
  }, [])
}

export default useAdsInfo
