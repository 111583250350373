import { Route, useNavigate } from '@tanstack/router'
import { useEffect } from 'react'

import inIframe from '../utils/inIframe'
import { rootRoute } from './root'

export const gameBackRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/game-back',
  component: () => {
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate()

    useEffect(() => {
      const search = Object.fromEntries(params.entries())
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'back', ...search }),
        )
      } else if (inIframe()) {
        window.parent.postMessage(
          JSON.stringify({ type: 'back', ...search }),
          '*',
        )
      } else {
        navigate({ to: '/' })
      }
    }, [])

    return null
  },
})
