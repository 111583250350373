import './bottom-nav.css'

import { useModal } from '@ebay/nice-modal-react'
import { Link } from '@tanstack/router'
import { type ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import best_odds from '../../assets/common/best_odds.webp'
import casino_after from '../../assets/common/casino_after.webp'
import casino_before from '../../assets/common/casino_before.webp'
import deportes_after from '../../assets/common/deportes_after.webp'
import deportes_before from '../../assets/common/deportes_before.webp'
import favoritos_after from '../../assets/common/favoritos_after.webp'
import favoritos_before from '../../assets/common/favoritos_before.webp'
import maximize from '../../assets/common/maximize.webp'
import minimize from '../../assets/common/minimize.webp'
import more_games from '../../assets/common/more_games.webp'
import more_live from '../../assets/common/more_live.webp'
import recently_after from '../../assets/common/recently_after.webp'
import recently_before from '../../assets/common/recently_before.webp'
import referral_after from '../../assets/common/referral_after.webp'
import referral_before from '../../assets/common/referral_before.webp'
import useGames from '../../hooks/useGames'
import useGeoCheck from '../../hooks/useGeoCheck'
import { useBoundStore } from '../../store/store'
import classNames from '../../utils/classNames'
import SearchModal from '../casino/search-modal'
import LoginModal from '../common/log-in-modal'

type TabObj = {
  icon: ReactNode
  badge?: ReactNode
  label: string
  link: string
  onLinkClick: undefined | (() => void)
  isProtected: boolean
}

const BottomNav = () => {
  const username = useBoundStore(state => state.user.username)
  const isFullScreen = useBoundStore(state => state.common.isFullScreen)
  const setIsFullScreen = useBoundStore(state => state.common.setIsFullScreen)
  const { geoCheck } = useGeoCheck()
  const { loadMoreGames } = useGames()
  const logInModal = useModal(LoginModal)
  const searchModal = useModal(SearchModal)
  const {
    t,
    i18n: { language },
  } = useTranslation(['drawer', 'casino'])
  const fullPath = location.pathname + location.search

  const commonTabs: TabObj[] = [
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Referral"
            src={referral_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Referral"
            src={referral_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('invite'),
      link: '/profile/referral/',
      onLinkClick: undefined,
      isProtected: true,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Favoritos"
            src={favoritos_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Favoritos"
            src={favoritos_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('casino:favorites'),
      link: '/casino/favoritos',
      onLinkClick: undefined,
      isProtected: true,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('sports'),
      link: language === 'zh' ? '/sports/im' : '/sports/bti',
      onLinkClick: undefined,
      isProtected: false,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino"
            src={casino_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino"
            src={casino_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('casino'),
      link: `/casino/popular`,
      onLinkClick: () => {
        loadMoreGames('15648', '', true)
      },
      isProtected: false,
    },
  ]

  const sportTabs: TabObj[] = [
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino"
            src={casino_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino"
            src={casino_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('casino'),
      link: `/casino/popular`,
      onLinkClick: undefined,
      isProtected: false,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      badge: (
        <img
          className="absolute w-[25px] top-[-7px] z-[5] right-[5px]"
          alt="Sports"
          src={language === 'zh' ? more_live : best_odds}
        />
      ),
      label: `${t('sports')} 1`,
      link: language === 'zh' ? '/sports/im' : '/sports/bti',
      onLinkClick: undefined,
      isProtected: false,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      badge: (
        <img
          className="absolute w-[25px] top-[-7px] z-[5] right-[5px]"
          alt="Sports"
          src={language === 'zh' ? best_odds : more_games}
        />
      ),
      label: `${t('sports')} 2`,
      link: language === 'zh' ? '/sports/bti' : '/sports',
      onLinkClick: undefined,
      isProtected: false,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      badge: (
        <img
          className="absolute w-[25px] top-[-7px] z-[5] right-[5px]"
          alt="Sports"
          src={language === 'zh' ? more_games : more_live}
        />
      ),
      label: `${t('sports')} 3`,
      link: language === 'zh' ? '/sports' : '/sports/im',
      onLinkClick: undefined,
      isProtected: false,
    },
  ]

  const casinoTabs: TabObj[] = [
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Deportes"
            src={deportes_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('sports'),
      link: language === 'zh' ? '/sports/im' : '/sports/bti',
      onLinkClick: undefined,
      isProtected: false,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Favoritos"
            src={favoritos_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Favoritos"
            src={favoritos_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('casino:favorites'),
      link: '/casino/favoritos',
      onLinkClick: undefined,
      isProtected: true,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Jugar"
            src={recently_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Jugar"
            src={recently_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('recent'),
      link: '/casino/jugar',
      onLinkClick: () => {
        loadMoreGames('recent_played', '', true)
      },
      isProtected: true,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino"
            src={casino_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino"
            src={casino_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('casino'),
      link: `/casino/popular`,
      onLinkClick: () => {
        loadMoreGames('15648', '', true)
      },
      isProtected: false,
    },
  ]

  const casinoGameTabs: TabObj[] = [
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino"
            src={casino_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino"
            src={casino_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('casino'),
      link: `/casino/popular`,
      onLinkClick: () => {
        loadMoreGames('15648', '', true)
      },
      isProtected: false,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Jugar"
            src={recently_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Jugar"
            src={recently_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('recent'),
      link: '/casino/jugar',
      onLinkClick: () => {
        loadMoreGames('recent_played', '', true)
      },
      isProtected: true,
    },
    {
      icon: (
        <div className="gif relative overflow-hidden w-[30px] h-[30px] mt-[-5px] mr-[-5px] mb-[-5px] ml-[-5px]">
          <LazyLoadImage
            className="icon-after opacity-0 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Favoritos"
            src={favoritos_after}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
          <LazyLoadImage
            className="icon-before opacity-1 absolute w-4/5 h-[unset] aspect-square left-2/4 top-2/4"
            alt="Casino Favoritos"
            src={favoritos_before}
            style={{ transform: 'var(--translate-50-50) rotate(0deg)' }}
          />
        </div>
      ),
      label: t('casino:favorites'),
      link: '/casino/favoritos',
      onLinkClick: undefined,
      isProtected: true,
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#888"
          width="24"
          height="24">
          <path
            className="!fill-none"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      ),
      label: t('casino:search-category'),
      link: '',
      onLinkClick: () => {
        searchModal.show()
      },
      isProtected: true,
    },
  ]

  const tabs: TabObj[] = useMemo(() => {
    // Game Page
    if (/\/casino\/.+\//.test(fullPath)) return casinoGameTabs
    if (fullPath.includes('/casino/')) return casinoTabs
    if (fullPath.includes('/sports')) return sportTabs
    return commonTabs
  }, [fullPath, casinoGameTabs, casinoTabs, commonTabs, sportTabs])

  return (
    <div
      className="fixed z-[120] bg-primary shadow-[0px_-2px_5px_rgba(0,0,0,0.1)] grid grid-cols-[repeat(5,1fr)] h-[60px] items-center w-full transition-all duration-[0.2s] ease-[ease-in-out] px-3 py-0 left-0 bottom-0"
      style={{ bottom: isFullScreen ? -60 : 0 }}>
      {tabs.map(tab =>
        tab.link ? (
          <Link
            to={tab.isProtected && !username ? undefined : tab.link}
            key={tab.label}
            onClick={tab.onLinkClick}
            search={{}}
            params={{}}>
            <div
              className={classNames(
                'tab flex flex-col items-center relative',
                '[&_svg]:w-[25px] [&_svg]:transition-all [&_svg]:duration-[0.2s] [&_svg]:ease-[ease-in-out]',
                tab.link.includes(`/casino/popular`)
                  ? fullPath.includes('/casino') &&
                    !fullPath.includes('favoritos') &&
                    !fullPath.includes('jugar')
                    ? '[&_p]:text-highlight [&_svg]:fill-highlight [&_path]:fill-highlight active'
                    : '[&_p]:text-[#888] [&_svg]:fill-[#888] [&_path]:fill-[#888]'
                  : fullPath === tab.link
                  ? '[&_p]:text-highlight [&_svg]:fill-highlight [&_path]:fill-highlight active'
                  : fullPath === tab.link
                  ? '[&_p]:text-highlight [&_svg]:fill-highlight [&_path]:fill-highlight active'
                  : '[&_p]:text-[#888] [&_svg]:fill-[#888] [&_path]:fill-[#888]',
              )}
              onClick={() => {
                if (tab.isProtected && !username) {
                  geoCheck(logInModal.show)
                }
              }}>
              {tab.badge}
              <div className="w-[30px] h-[30px] flex justify-center items-center transition-all duration-[0.2s] ease-[ease-in-out] relative rounded-[50%]">
                {tab.icon}
              </div>
              <p className="text-[10px] uppercase text-center">{tab.label}</p>
            </div>
          </Link>
        ) : (
          <button key={tab.label} onClick={tab.onLinkClick}>
            <div
              className={classNames(
                'tab flex flex-col items-center relative',
                '[&_svg]:w-[25px] [&_svg]:transition-all [&_svg]:duration-[0.2s] [&_svg]:ease-[ease-in-out]',
                '[&_p]:text-[#888] [&_svg]:fill-[#888] [&_path]:fill-[#888]',
              )}
              onClick={() => {
                if (tab.isProtected && !username) {
                  geoCheck(logInModal.show)
                }
              }}>
              <div className="w-[30px] h-[30px] flex justify-center items-center transition-all duration-[0.2s] ease-[ease-in-out] relative rounded-[50%]">
                {tab.icon}
              </div>
              <p className="text-[10px] uppercase text-center">{tab.label}</p>
            </div>
          </button>
        ),
      )}
      <div />
      <div
        className={classNames(
          'tab flex flex-col items-center absolute w-[calc(100%_/_4_-_24px)] transition-all duration-[0.2s] ease-[ease-in-out] right-3',
          isFullScreen ? 'bottom-[68px]' : 'bottom-2',
        )}
        onClick={() => setIsFullScreen(!isFullScreen)}>
        <div className="w-[30px] h-[30px] flex justify-center items-center transition-all duration-[0.2s] ease-[ease-in-out] relative rounded-[50%]">
          <img
            className={classNames(
              'w-[25px] transition-all duration-[0.2s] ease-[ease-in-out]',
              isFullScreen && 'w-[40px]',
            )}
            src={isFullScreen ? minimize : maximize}
            alt="hide"
            style={{ rotate: `${isFullScreen ? 0 : 180}deg` }}
          />
        </div>
        {!isFullScreen && (
          <p className="text-[10px] uppercase text-[#888] text-center">
            {t('expand')}
          </p>
        )}
      </div>
    </div>
  )
}

export default BottomNav
