import { lazy,Route } from '@tanstack/router'

import { blogRoute } from '.'

const Blogs = lazy(() => import('../../pages/blogs/blogs'))

export const blogsListRoute = new Route({
  getParentRoute: () => blogRoute,
  path: '/',
  component: Blogs,
})
