import { lazy,Route } from '@tanstack/router'

import type { RegisterSearch } from './register'
import { rootRoute } from './root'

const SportsRegister = lazy(() => import('../pages/sports-register'))

export const sportsRegister = new Route({
  getParentRoute: () => rootRoute,
  path: '/sports-register',
  component: SportsRegister,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
