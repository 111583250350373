import { lazy,Route } from '@tanstack/router'

import { profileRoute } from '.'

const AppFeedback = lazy(() => import('../../pages/profile/app-feedback'))

export const appFeedbackRoute = new Route({
  getParentRoute: () => profileRoute,
  path: '/feedback',
  component: AppFeedback,
})
