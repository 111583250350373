import type { NiceModalHandler } from '@ebay/nice-modal-react'
import { useModal } from '@ebay/nice-modal-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useAppleLogin from '../../hooks/useAppleLogin'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import type { ThirdPartyPlatforms } from '../../types/common'
import type { UserInfo } from '../../types/user'
import { api } from '../../utils/api-client'
import closeModal from '../../utils/closeModal'
import { getDeviceInfo } from '../../utils/device'
import { encryptUser } from '../../utils/user'
import Icon from '../icon'
import FillUsername from '../register/fill-username'
import { ToastMessage } from '.'
import Spinner from './spinner'

// const InstagramIcon = (
//   <svg width="40" height="40" viewBox="0 0 200 200">
//     <defs>
//       <radialGradient
//         id="radialGradient"
//         cx="-21.85"
//         cy="270.1"
//         r="416.95"
//         gradientUnits="userSpaceOnUse">
//         <stop offset="0" stopColor="#f9ed41" />
//         <stop offset="0.24" stopColor="#ff833d" />
//         <stop offset="0.4" stopColor="#ee5568" />
//         <stop offset="0.49" stopColor="#e7407b" />
//         <stop offset="1" stopColor="#0028ff" />
//       </radialGradient>
//     </defs>
//     <rect fill="url(#radialGradient)" width="200" height="200" rx="100" />
//     <path
//       fill="#fff"
//       d="M121.87,156.4H78.13A34.57,34.57,0,0,1,43.6,121.87V78.13A34.57,34.57,0,0,1,78.13,43.6h43.74A34.57,34.57,0,0,1,156.4,78.13v43.74A34.57,34.57,0,0,1,121.87,156.4ZM78.13,52A26.13,26.13,0,0,0,52,78.13v43.74A26.13,26.13,0,0,0,78.13,148h43.74A26.13,26.13,0,0,0,148,121.87V78.13A26.13,26.13,0,0,0,121.87,52Z"
//     />
//     <path
//       fill="#fff"
//       d="M100,128.53A28.53,28.53,0,1,1,128.53,100,28.56,28.56,0,0,1,100,128.53Zm0-48.42A19.89,19.89,0,1,0,119.88,100,19.91,19.91,0,0,0,100,80.11Z"
//     />
//     <path
//       fill="#fff"
//       d="M139.07,68.36a7.43,7.43,0,1,1-7.43-7.43A7.43,7.43,0,0,1,139.07,68.36Z"
//     />
//   </svg>
// )

interface SocialMediaAuthProps {
  callback?: (user: UserInfo, status: number) => void
  page: 'Login' | 'Register'
}

type AuthUrls = {
  [key in ThirdPartyPlatforms]?: {
    url: string
    id: string
  }
}

const SocialMediaAuth = ({ callback, page }: SocialMediaAuthProps) => {
  const { t } = useTranslation('register')
  const params = new URLSearchParams(window.location.search)
  const enableFacebook = useBoundStore(state => state.settings.login_facebook)
  const enableTelegram = useBoundStore(state => state.settings.login_telegram)
  const enableGoogle = useBoundStore(state => state.settings.login_google)
  const enableTwitter = useBoundStore(state => state.settings.login_twitter)
  const enableTwitch = useBoundStore(state => state.settings.login_twitch)
  const enableIos = useBoundStore(state => state.settings.login_ios)

  const PLATFORMS = [
    {
      icon: <Icon id="facebook" width="40" height="40" />,
      key: 'facebook',
      label: 'Facebook',
      enabled: !!enableFacebook.value,
    },
    {
      icon: <Icon id="telegram" width="40" height="40" />,
      key: 'telegram',
      label: 'Telegram',
      enabled: !!enableTelegram.value,
    },
    {
      icon: <Icon id="google" width="40" height="40" />,
      key: 'google',
      label: 'Google',
      enabled: !!enableGoogle.value,
    },
    {
      icon: <Icon id="twitter-2" width="40" height="40" />,
      key: 'twitter',
      label: 'Twitter',
      enabled: !!enableTwitter.value,
    },
    {
      icon: <Icon id="twitch" width="40" height="40" />,
      key: 'twitch',
      label: 'Twitch',
      enabled: !!enableTwitch.value,
    },
    // { icon: <Icon id="whatsapp" width="40" height="40" />, key: 'whatsapp' },
  ] as const

  const FILTERED_PLATFORMS = PLATFORMS.filter(platform => platform.enabled)

  const fillUsername = useModal(FillUsername)
  const toastMessage = useModal(ToastMessage)
  const setUserStore = useBoundStore(state => state.user.setUser)
  const [thirdPartyLoading, setThirdPartyLoading] =
    useState<ThirdPartyPlatforms | null>(null)
  const [authUrls, setAuthUrls] = useState<AuthUrls>({})

  useAppleLogin(setThirdPartyLoading, callback)

  const checkLoginStatusPoll = async (
    hash: string,
    platform: ThirdPartyPlatforms,
    user?: {
      username: string
      promo_code: string
    },
  ) => {
    try {
      const response = await api.post<
        ApiResponse<
          (UserInfo & { password?: string; auth_id?: string }) | string
        >
      >(`/oauth/${platform}/callback.php`, {
        auth_id: hash,
        action: page.toLowerCase(),
        ...(user ?? {}),
        meta: {
          ...getDeviceInfo(),
          ...Object.fromEntries(params.entries()),
        },
      })
      const { status, info } = response.data

      if (status === 0 && typeof info === 'string') {
        setThirdPartyLoading(null)
        toastMessage.show({ text: info, isValid: false })
        return
      }

      if ((status === 1 || status === 2) && typeof info !== 'string') {
        if (info.auth_id && info.email) {
          fillUsername.show({
            onSubmit: user =>
              checkLoginStatusPoll(info.auth_id ?? '', platform, user),
          })
          return
        } else if (info.username) {
          encryptUser({
            username: info.username,
            password: info.password as string,
          })
          delete info.password
          setUserStore(info)

          setThirdPartyLoading(null)
          closeModal(fillUsername as NiceModalHandler)
          callback?.(info, user ? 2 : 1)
          return
        }
      }
    } catch (error) {
      setThirdPartyLoading(null)
      console.error(error)
      return
    }

    checkLoginStatusPoll(hash, platform)
  }

  useEffect(() => {
    const getAuthUrl = async (platform: ThirdPartyPlatforms) => {
      const response = await api.get<
        ApiResponse<
          | {
              url: string
              id: string
            }
          | string
        >
      >(`/oauth/${platform}/authenticate.php`)
      const { info } = response.data

      if (typeof info !== 'string') {
        setAuthUrls(prev => ({ ...prev, [platform]: info }))
      }
    }

    FILTERED_PLATFORMS.map(platform => getAuthUrl(platform.key))
  }, [])

  if (FILTERED_PLATFORMS.length === 0 && !enableIos.value) return null

  return (
    <div className="mt-3">
      {FILTERED_PLATFORMS.map(platform =>
        !authUrls[platform.key] ? null : thirdPartyLoading === platform.key ? (
          <Spinner
            containerStyle={{
              height: 39,
              marginBottom: 12,
            }}
          />
        ) : (
          <button
            className="w-full text-center relative border text-sm text-highlight mb-3 px-0 py-2 rounded-md border-solid border-highlight disabled:opacity-70 last:mb-0 [&>svg]:absolute [&>svg]:-translate-y-2/4 [&>svg]:w-[19px] [&>svg]:h-[19px] [&>svg]:left-2.5 [&>svg]:top-2/4"
            type="button"
            key={platform.key}
            onClick={() => {
              window.open(authUrls[platform.key]?.url, '_blank')
              setThirdPartyLoading(platform.key)
              checkLoginStatusPoll(
                authUrls[platform.key]?.id ?? '',
                platform.key,
              )
            }}
            disabled={!!thirdPartyLoading}>
            {platform.icon}{' '}
            {page === 'Login' ? t('login-with') : t('register-with')}{' '}
            {platform.label}
          </button>
        ),
      )}
      {!!enableIos.value &&
        (thirdPartyLoading === 'apple' ? (
          <Spinner
            containerStyle={{
              height: 39,
            }}
          />
        ) : (
          <button
            className="w-full text-center relative border text-sm text-highlight mb-3 px-0 py-2 rounded-md border-solid border-highlight disabled:opacity-70 last:mb-0 [&>svg]:absolute [&>svg]:-translate-y-2/4 [&>svg]:w-[19px] [&>svg]:h-[19px] [&>svg]:left-2.5 [&>svg]:top-2/4"
            type="button"
            onClick={() => {
              setThirdPartyLoading('apple')
              AppleID.auth.signIn()
            }}
            disabled={!!thirdPartyLoading}>
            <Icon id="apple" width="40" height="40" />
            {page === 'Login' ? t('login-with') : t('register-with')} Apple
          </button>
        ))}
    </div>
  )
}

export default SocialMediaAuth
