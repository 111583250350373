import sha256 from 'crypto-js/sha256'
import { useEffect } from 'react'

import { useBoundStore } from '../store/store'
import { getAdsInfo } from '../utils/ads-info'

function useFbPixel() {
  const email = useBoundStore(state => state.user.email)
  const phone = useBoundStore(state => state.user.phone)
  const userId = useBoundStore(state => state.user.userID)
  const isUserLoading = useBoundStore(state => state.user.isLoading)
  const userIP = useBoundStore(state => state.settings.userIP)

  useEffect(() => {
    if (isUserLoading) return
    const adsInfo = getAdsInfo()
    fbq('init', import.meta.env.VITE_FB_PIXEL_ID, {
      em: [email ? sha256(email).toString() : null],
      ph: [phone ? sha256(phone).toString() : null],
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP,
      external_id: [
        userId ? sha256(userId).toString() : null,
      ],
      fbc: adsInfo.fbclid ?? null,
      fbp: adsInfo.fbp ?? null,
    })
  }, [isUserLoading])
}

export default useFbPixel
