import { lazy, Route } from '@tanstack/router'

import type { RegisterSearch } from './register'
import { rootRoute } from './root'

const BingoRegister = lazy(() => import('../pages/bingo-register'))

export const bingoRegisterRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/bingo-register',
  component: BingoRegister,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
