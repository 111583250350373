import NiceModal, { useModal } from '@ebay/nice-modal-react'
import type { MakeLinkOptions } from '@tanstack/router'
import { useNavigate, useSearch } from '@tanstack/router'
import sha256 from 'crypto-js/sha256'
import {
  type ChangeEvent,
  type FormEvent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'

import useBlazeSlider from '../../hooks/useBlazeSlider'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import type { UserInfo } from '../../types/user'
import { api } from '../../utils/api-client'
import classNames from '../../utils/classNames'
import closeModal from '../../utils/closeModal'
import { getDeviceInfo } from '../../utils/device'
import { getPublicAuth } from '../../utils/getPublicAuth'
import { encryptUser } from '../../utils/user'
import { Spinner } from '../common'
import { TextInput } from '../register'
import DuplicateAccountModal from './duplicate-account-modal'
import Modal from './modal'
import RequestPasswordResetModal from './request-password-reset-modal'
import SocialMediaAuth from './social-media-auth'

const LoginModal = () => {
  const { t } = useTranslation(['login', 'walletManagement', 'common'])
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { redirectUrl } = useSearch() as { redirectUrl?: string }
  const setUserStore = useBoundStore(state => state.user.setUser)
  const b1 = useBoundStore(state => state.settings.login_banner1)
  const b2 = useBoundStore(state => state.settings.login_banner1)
  const b3 = useBoundStore(state => state.settings.login_banner3)
  const b4 = useBoundStore(state => state.settings.login_banner4)
  const b5 = useBoundStore(state => state.settings.login_banner5)
  const b6 = useBoundStore(state => state.settings.login_banner6)

  const enableSocialMediaAuth = useBoundStore(
    state => state.settings.social_media_auth,
  )
  const modal = useModal()
  const requestPasswordResetModal = useModal(RequestPasswordResetModal)
  const duplicateModal = useModal(DuplicateAccountModal)
  const navigate = useNavigate()
  const [user, setUser] = useState({
    username: '',
    password: '',
  })
  const [isRemember, setIsRemember] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState('')

  const banners = useMemo(() => {
    return [b1, b2, b3, b4, b5, b6].filter(banner => !!banner.value)
  }, [b1, b2, b3, b4, b5, b6])

  const { elRef, activeKey, slideTo } = useBlazeSlider({
    all: {
      enableAutoplay: true,
      slidesToShow: 1,
    },
  })

  useEffect(() => {
    if (modal.visible) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'login_form_open',
          event_category: 'login',
          event_action: 'click_open',
          event_label: 'Open Login Form',
        },
      })
    }
  }, [modal.visible])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSuccess = (info: UserInfo, status: number) => {
    // Register
    if (status === 2) {
      api.post('/conduct.php', {
        type: 'activate_game',
      })

      TagManager.dataLayer({
        dataLayer: {
          event: 'registration_success',
          event_category: 'register',
          event_action: 'action_complete',
          event_label: 'Registration Third Step',
        },
      })

      // FB
      fbq('track', 'CompleteRegistration', {
        username: info.username,
      })
      // Twitter
      twq('event', 'tw-olsug-oltxg', {
        email_address: sha256(info.email).toString(),
        phone_number: sha256(`+52${info.phone}`).toString(),
      })

      navigate({ to: '/' })
      closeModal(modal)
      return
    }
    // Login
    if (
      location.pathname.includes('/sorteos') ||
      location.pathname.includes('/sports')
    ) {
      location.reload()
    } else {
      if (redirectUrl) {
        navigate({ to: redirectUrl, search: {} } as MakeLinkOptions<'/'>)
        closeModal(modal)
        return
      }

      navigate({ to: '/' })
    }
    localStorage.removeItem('ads_info')
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_login',
        event_category: 'login',
        event_action: 'action_complete',
        event_label: 'Login Success',
        user_id: info.username,
      },
    })
    closeModal(modal)
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!user.username || !user.password) return

    setError('')
    setIsLoading(true)

    const publicAuth = await getPublicAuth()

    const reqBody = {
      ...user,
      username: user.username.toLowerCase(),
      submit_type: 'login',
      meta: getDeviceInfo(),
      auth: publicAuth,
    }

    const response = await api.post<ApiResponse<UserInfo>>(
      '/center.php',
      reqBody,
    )

    const { status, info } = response.data

    if (status === 1) {
      const privateAuth = await api.post<
        ApiResponse<string | { auth: string; expired_time: number }>
      >('/link.php', {
        username: info.username,
        password: user.password,
        auth: publicAuth,
      })
      if (
        privateAuth.data.status === 1 &&
        typeof privateAuth.data.info !== 'string'
      ) {
        localStorage.setItem('privateAuth', privateAuth.data.info.auth)
      } else {
        if (typeof privateAuth.data.info === 'string') {
          setError(privateAuth.data.info)
        }
        setIsLoading(false)
        return
      }
      encryptUser({ username: info.username, password: user.password })
      setUserStore(info)
      onSuccess(info, 1)
    } else if (status === 2) {
      if (typeof info === 'string') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        navigate({ to: info })
        closeModal(modal)
      }
    } else if (status === 3) {
      duplicateModal.show()
      closeModal(modal)
    } else {
      if (typeof info === 'string') setError(info as unknown as string)
    }
    setIsLoading(false)
  }

  return (
    <Modal
      id="log-in-modal"
      containerStyle="!bg-secondary !w-[calc(100%_-_32px)] !max-h-[calc(100vh_-_32px)] !overflow-y-auto !pt-5 !pb-[15px] !px-5 !rounded-[10px] !border-0">
      <div className="flex bg-primary justify-end -mt-5 mb-5 -mx-5 px-5 py-0 rounded-[20px_20px_0_0]">
        <button
          className="text-sm text-highlight px-0 py-2.5"
          onClick={() => {
            navigate({ to: '/register' })
            closeModal(modal)
          }}>
          {t('common:register').toUpperCase()}
        </button>
      </div>
      <div className="relative">
        <div className="flex justify-center items-center flex-row absolute translate-x-2/4 z-[1] gap-2 right-2/4 bottom-0">
          {banners.map((_banner, key) => (
            <div
              key={key}
              className={classNames(
                'w-1.5 h-1.5 transition-all duration-[0.2s] ease-linear cursor-pointer rounded-[50%]',
                key === activeKey ? 'bg-highlight' : '#888',
              )}
              onClick={() => slideTo(key)}
            />
          ))}
        </div>
        <div
          className="blaze-slider w-full max-w-[500px] mx-auto my-2.5 xl:mt-2.5 xl:mb-4 xl:mx-auto"
          ref={elRef}>
          <div className="blaze-container">
            <div className="blaze-track-container">
              <div className="blaze-track">
                {banners.map(banner => (
                  <div className="relative" key={banner.click_url}>
                    {banner.value && (
                      <img
                        className="w-full flex mb-3 rounded-md"
                        src={banner.value}
                        alt="banner"
                      />
                    )}
                    {banner.click_url && (
                      <a
                        className="text-white underline text-xs text-center w-full block absolute -translate-x-2/4 left-2/4 bottom-[8%]"
                        style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
                        href={banner.click_url}
                        target="blank">
                        {t('terms', { ns: 'homeV2' })}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        {error && (
          <p className="text-sm font-normal text-[rgb(255,64,88)] w-full mb-2.5 px-0 py-0.5 xl:text-base">
            {error}
          </p>
        )}
        <TextInput
          label={t('username', { ns: 'login' })}
          placeholder={`${t('example')}:Raul678`}
          value={user.username}
          name="username"
          onChange={onChange}
        />
        <TextInput
          label={t('password', { ns: 'login' })}
          isPassword
          value={user.password}
          name="password"
          onChange={onChange}
        />
        <label className="relative text-secondary-txt text-sm inline-block transition-all duration-[0.2s] ease-[ease-in-out] mb-4 pl-5">
          {t('remember-me')}
          <input
            className="hidden"
            type="checkbox"
            checked={isRemember}
            onChange={e => setIsRemember(e.target.checked)}
          />
          <span
            className={classNames(
              `inline-block w-3.5 h-3.5 bg-primary absolute -translate-y-2/4 rounded transition-all duration-[0.2s] ease-[ease-in-out] left-0 top-2/4 after:content-[''] after:absolute after:bg-primary-btn-bg after:transition-all after:duration-[0.2s] after:ease-[ease-in-out] after:w-2 after:h-2 after:rounded after:left-[3px] after:top-[3px]`,
              isRemember ? 'after:opacity-100' : 'after:opacity-0',
            )}
          />
        </label>
        <div className="flx-ctr">
          {isLoading ? (
            <Spinner spinnerSize={39} />
          ) : (
            <button
              className="block text-center bg-highlight text-[white] transition-all duration-[0.2s] ease-[ease-in-out] cursor-pointer uppercase text-sm w-full px-6 py-3 rounded-md disabled:bg-[#657f8c] xl:text-base"
              style={{ margin: '0 auto' }}
              type="submit">
              {t('login')}
            </button>
          )}
        </div>
      </form>
      <p
        className={`text-xs text-secondary-txt relative w-full text-center mt-3 before:left-0 after:right-0 before:content-[''] before:absolute before:w-[calc(50%_-_24px)] before:h-px before:bg-secondary-txt before:top-2 after:content-[''] after:absolute after:w-[calc(50%_-_24px)] after:h-px after:bg-secondary-txt after:top-2`}>
        {t('common:or')}
      </p>
      {enableSocialMediaAuth.value === 1 && (
        <SocialMediaAuth callback={onSuccess} page="Login" />
      )}
      <p
        className="text-highlight cursor-pointer underline text-sm text-center mt-4 mb-[5px]"
        onClick={() => {
          requestPasswordResetModal.show()
          closeModal(modal)
        }}>
        {t('forgot-password')}
      </p>
    </Modal>
  )
}

export default NiceModal.create(LoginModal)
