import { lazy, Route } from '@tanstack/router'

import favorites_active from '../../assets/casino/favorites_active.webp'
import favorites_light from '../../assets/casino/favorites_light.webp'
import favorites_off from '../../assets/casino/favorites_off.webp'
import { useBoundStore } from '../../store/store'
import type { CasinoSlice } from '../../store/types/casino'
import type { CasinoSearch } from '../../types/route-search'
import { api } from '../../utils/api-client'
import { decryptUser } from '../../utils/user'
import { casinoRoute } from '.'

const Casino = lazy(() => import('../../pages/casino'))

export const casinoCategoryRoute = new Route({
  getParentRoute: () => casinoRoute,
  path: '$category',
  component: Casino,
  validateSearch: (search: Record<string, unknown>): CasinoSearch => {
    return {
      platform: typeof search.platform === 'string' ? search.platform : '',
      error: typeof search.error === 'string' ? search.error : '',
      message: typeof search.message === 'string' ? search.message : '',
    }
  },
  loader: async () => {
    const user = decryptUser()

    const preLoadCategories = async () => {
      const categories = useBoundStore.getState().casino.categories
      const setCategories = useBoundStore.getState().casino.setCategories

      if (categories.length) return

      try {
        const res = await api.get<CasinoSlice['categories']>(
          `/games/categories.php?account=${user?.username ?? ''}`,
        )

        setCategories([
          ...res.data,
          {
            id: 'favorites',
            category: 'FAVORITOS',
            icon_active: favorites_active,
            icon_light: favorites_light,
            icon_off: favorites_off,
            count: 0,
          },
        ])
      } catch (error) {
        console.error(error)
      }
    }

    const preLoadProviders = async () => {
      const providers = useBoundStore.getState().casino.providers
      const setProviders = useBoundStore.getState().casino.setProviders

      if (Object.keys(providers || {}).length) return

      try {
        const res = await api.get<CasinoSlice>(
          `/games/lists.php?account=${user?.username ?? ''}`,
        )

        setProviders(res.data['providers'])
      } catch (error) {
        console.error(error)
      }
    }

    await preLoadCategories()
    await preLoadProviders()
  },
})
